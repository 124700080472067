<script setup lang="ts">
  // This starter template is using Vue 3 <script setup> SFCs
  // Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
  import { useStore } from './store/index'
  // import { useI18n } from 'vue-i18n'
  import { ref } from 'vue'

  // import Step1 from './components/steps/Step1.vue'
  // import Step2 from './components/steps/Step2.vue'

  const store = useStore()

  const urlParameters = Object.fromEntries(new URLSearchParams(location.search.substring(1)))

  store.$patch({
    urlParameters: {
      ...urlParameters,
    },
  })

  // const { t, locale } = useI18n()

  // document.title = t('pageTitleA')

  // const titleCounter = ref(0)

  // function titleChanger() {
  //   if (titleCounter.value % 2 === 0) {
  //     document.title = t('pageTitleA')
  //   } else {
  //     document.title = t('pageTitleB')
  //   }
  //   titleCounter.value++
  // }

  // setInterval(function () {
  //   titleChanger()
  // }, 2000)

  // watchEffect(() => {
  //   if (store.urlParameters !== undefined) {
  //     locale.value = store.urlParameters.lang ?? 'en'
  //   }
  // })
  // const stepNumber = ref(1)

  // function increaseStep() {
  //   stepNumber.value++
  // }

  // function currentStep() {
  //   if (stepNumber.value === 1) return Step1
  //   if (stepNumber.value === 2) return Step2
  // }
</script>

<template>
  <router-view />
</template>
